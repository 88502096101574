


































































import Vue from "@/vue-ext";
import { Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import ChooseLanguage from "../components/sections/choose-language.vue";
import Layout from "../components/layouts/splash-layout.vue";
import Loader from "../components/common/loader.vue";
import CountInputField from "../components/common/count-input-field.vue";
import SignupStoreModule from "../store/modules/signup";
import { TranslateResult } from "vue-i18n";
import { SUPPORTED_REGIONS } from "../services/signup-api"

@Component({
  components: {
    ChooseLanguage,
    Layout,
    Loader,
    CountInputField,
  },
})
export default class SignupResultPage extends Vue {
  store: SignupStoreModule = getModule(SignupStoreModule, this.$store);

  isOpen = false;
  adults = 1;
  children = 0;

  invitationCode = "";
  processing = false;
  errorMessage: TranslateResult = "";

  selectedCountry: Record<string, any> = { indexes: [], value: [] };

  get selectedRegion() {
    return this.countries[this.selectedCountry?.indexes?.[0]]?.value;
  }

  get countries() {
    return Object.entries(SUPPORTED_REGIONS).map(
      ([value, label]) => ({ label, value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  created(): void {
    const { code, region } = this.$route.params;
    if (code) {
      this.invitationCode = code;
    }
    if (region) {
      const selectedRegion = this.countries.find(
        (country) => country.value === region
      );
      if (selectedRegion) {
        const selectedRegionIndex = this.countries.indexOf(selectedRegion);
        this.selectedCountry = {
          indexes: [selectedRegionIndex],
          value: [selectedRegion],
        };
      } else {
        this.selectedCountry = { indxes: [0], value: [this.countries[0]] };
      }
    }
    if (region && code) {
      this.store.loadOrganizationByInvitationCode({ code, region });
    }
  }

  get organization(): string | undefined {
    return this.store.signupState.organization;
  }

  createAccount(): void {
    this.$router.push({
      name: "signup",
      params: {
        type: "corporate",
        section: "1",
      },
      query: {
        code: this.$route.params.code,
        adults: `${this.adults}`,
        children: `${this.children}`,
        region: `${this.selectedRegion}`
      },
    });
  }

  getNumberOfPeople(adult: number, children: number): TranslateResult {
    const adultDisplay =
      adult > 1 ? this.$t("{adult} Adults", { adult }) : this.$t("1 Adult");

    let childrenDisplay;

    if (children > 0)
      childrenDisplay =
        children > 1
          ? this.$t(",{children} Children", { children })
          : this.$t(",1 Child");

    return [adultDisplay, childrenDisplay].join("");
  }

  async submit(): Promise<void> {
    this.processing = true;
    this.errorMessage = "";

    try {
      const organization = await this.store.loadOrganizationByInvitationCode({
        code: this.invitationCode,
        region: this.selectedRegion
      });

      if (organization) {
        this.$router.push({
          params: {
            code: this.invitationCode,
          },
        });
      } else {
        this.errorMessage = this.$t("Invalid invitation code.");
      }
    } catch (ex) {
      console.error(ex);
      this.errorMessage = this.$t(
        "Something went wrong while processing invitation code. Please try again later."
      );
    } finally {
      this.processing = false;
    }
  }
}
